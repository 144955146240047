import React, { useState, useEffect } from "react";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Spinner from "elements/Spinner";
import api from "../services/api";
import Sidebar from "../elements/Sidebar";
import ls from "../services/ls";
import { useLocation, useHistory } from "react-router-dom";
import AdminSidebar from "../elements/AdminSidebar";
import LogoIcon from "../assets/images/logos/notification.png";

import addNotification from "react-push-notification";

const FullLayout = ({ children, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname.slice(1);

  const [rolePermission, setRolePermission] = useState({});
  const getRolePermission = async () => {
    var response = await api.get("/get_role_permission");
    if (response.status === 200 && response.data) {
      setRolePermission(response.data.data);
    } else {
      console.log(response.data.data);
    }
  };

  const [callNotifications, setCallNotifications] = useState(false);
  //Notifications
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const getNotifications = async () => {
    try {
      const response = await api.post("/notifications", { is_read: 0 });
      if (response.status === 200 && response.data) {
        setUnreadNotifications(response.data.notifications || []);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      // Initial fetch of notifications when user logs in
      getNotifications();

      // Fetch notifications every 5 minutes
      const interval = setInterval(() => {
        getNotifications();
      }, 180000); // 5 minutes in milliseconds

      // Clean up interval on unmount or when auth state changes
      return () => clearInterval(interval);
    }
  }, [auth.isAuthenticated()]);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      getNotifications();
    }
  }, [callNotifications]);

  const handleNotificationClick = async (notification) => {
    try {
      const response = await api.post("/notifications-read", {
        id: notification.id,
      });
      if (response.status === 200 && response.data) {
        // After marking the notification as read, you may choose to update the notifications list
        getNotifications();
        history.push(notification.url);
      }
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  useEffect(() => {
    // Display notifications
    unreadNotifications.forEach((notification) => {
      const notificationOptions = {
        title: notification.title,
        message: notification.description,
        theme: "darkblue",
        onClick: () => handleNotificationClick(notification),
        duration: 86400000, // 24 hours in milliseconds
        native: true,
        icon: LogoIcon,
      };
      addNotification(notificationOptions);
    });
  }, [unreadNotifications]);

  const [spinner, setSpinner] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userData, setUserData] = useState(auth.getUser);
  const [sidebar, setSideBar] = useState(ls.get("sidebar"));
  const [section, setSection] = useState("dashboard");
  const [itemModal, setItemModal] = useState(false);
  const [callItems, setCallItems] = useState(false);
  const [buyerModal, setBuyerModal] = useState(false);
  const [callBuyers, setCallBuyers] = useState(false);
  const [styleModal, setStyleModal] = useState(false);
  const [callStyles, setCallStyles] = useState(false);
  const [colorModal, setColorModal] = useState(false);
  const [callColors, setCallColors] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);
  const [callSizes, setCallSizes] = useState(false);
  const [unitModal, setUnitModal] = useState(false);
  const [callUnits, setCallUnits] = useState(false);
  const [partModal, setPartModal] = useState(false);
  const [callParts, setCallParts] = useState(false);

  //ISSUE ON CANVAS
  const [substoreIssueCanvas, setSubstoreIssueCanvas] = useState(false);
  const [callSubstores, setCallSubstores] = useState(false);
  const [substoreCanvasId, setSubstoreCanvasId] = useState(null);

  //RECEIVE ON CANVAS
  const [substoreReceiveCanvas, setSubstoreReceiveCanvas] = useState(false);
  const [requisitionItemId, setRequisitionItemId] = useState(null);
  const [callRequisition, setCallRequisition] = useState(false);

  const [sampleTypeModal, setSampleTypeModal] = useState(false);
  const [callSampleTypes, setCallSampleTypes] = useState(false);

  const passableParams = {
    isAuthenticated,
    setSpinner,
    spinner,
    userData,
    rolePermission,
    unreadNotifications,

    // sidebar
    sidebar,
    setSideBar,
    // section
    section,
    setSection,
    // modals
    // items
    itemModal,
    setItemModal,
    callItems,
    setCallItems,
    // buyers
    buyerModal,
    setBuyerModal,
    callBuyers,
    setCallBuyers,
    // styles
    styleModal,
    setStyleModal,
    callStyles,
    setCallStyles,
    // colors
    colorModal,
    setColorModal,
    callColors,
    setCallColors,
    // sizes
    sizeModal,
    setSizeModal,
    callSizes,
    setCallSizes,
    // units
    unitModal,
    setUnitModal,
    callUnits,
    setCallUnits,
    // sampleTypes
    sampleTypeModal,
    setSampleTypeModal,
    callSampleTypes,
    setCallSampleTypes,
    partModal,
    setPartModal,
    callParts,
    setCallParts,

    //ISSUEcanvas
    substoreIssueCanvas,
    setSubstoreIssueCanvas,
    //ISSUEcanvasId
    substoreCanvasId,
    setSubstoreCanvasId,
    //receive canvas
    substoreReceiveCanvas,
    setSubstoreReceiveCanvas,
    requisitionItemId,
    setRequisitionItemId,
    callRequisition,
    setCallRequisition,
    //dfuifudiu
    callSubstores,
    setCallSubstores,
    callNotifications,
    setCallNotifications,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  useEffect(async () => {
    if (isAuthenticated) {
      getRolePermission();
    }
  }, []);

  return (
    <>
      {spinner && <Spinner />}

      {pathname === "login" ? (
        <main>{childrenWithProps}</main>
      ) : (
        <div className="home">
          {userData?.role === "Admin" ? (
            <AdminSidebar {...passableParams} />
          ) : (
            <Sidebar {...passableParams} />
          )}

          <div className={sidebar ? "content" : "content open"}>
            <Header {...passableParams} />
            <div className="all_pages">
              <main>{childrenWithProps}</main>
            </div>
          </div>
          <a
            href="#"
            className="btn btn-lg btn-warning bg-falgun btn-lg-square back-to-top"
          >
            <i className="fas fa-angle-up"></i>
          </a>
        </div>
      )}
      <ScrollTo />
    </>
  );
};

export default FullLayout;
